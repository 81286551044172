import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Suspense>
    <App />
  </Suspense>
);

